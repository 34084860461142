@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-base;
  @apply text-shades-600;
  @apply bg-white;
  flex: 1;
  position: relative;
  overflow-x: hidden;
}

body > div:first-child,
div#__next,
div#__next > div,
.app {
  min-height: 100%;
  height: 100%;
  height: -webkit-fill-available;
}

.height-full {
  height: '100%';
  height: -webkit-fill-available;
}

html {
  min-height: 100%;
  height: 100%;
  height: -webkit-fill-available;
  display: flex;
}

/* NextJS unfortunately applies a non-configurable blur filter to all placeholder images, the only way to remove it is to override it */
.noblur img {
  filter: none !important;
}

/* ---
  Fades - In/Out
  -----------------------------------------------------------------------*/
.fadein {
  -webkit-animation: fadeIn 0.3s linear forwards;
  animation: fadeIn 0.3s linear forwards;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}

.fadeout {
  -webkit-animation: fadeOut 0.3s linear forwards;
  animation: fadeOut 0.3s linear forwards;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0.85;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.85;
  }
  100% {
    opacity: 0;
  }
}

@keyframes changewidth {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1.2);
  }
}

.pulse {
  animation-duration: 0.7s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/**
  The below is a CSS mechanism to prevent scrolling an element on a mobile device when the
  OS doesn't respect the overflow: hidden property on the body (namely iOS). It's particularly
  handy when the OS opens the page in a virtual window (ex: when virtual keyboard is open or when
  a page is opened in an in-app browser)

  There's 2 parts:

  1. Apply the no-scroll-when-overlay-open or no-scroll-when-overlay-open-deep (when you want all child elements
     not to scroll) class to any element that should not scroll,
  2. add the mobile-overlay-open class to the body

  NOTE:
    touch-action:none property sometimes needs to be added to specific elements to work, so you
    may in some cases need to add the no-scroll class to parent and child elements
*/
.mobile-overlay-open {
  overflow: hidden;
}
.mobile-overlay-open .no-scroll-when-overlay-open,
.mobile-overlay-open .no-scroll-when-overlay-open-deep,
.mobile-overlay-open .no-scroll-when-overlay-open-deep * {
  touch-action: none;
}

.isCREABlocked {
  filter: blur(10px);
}

/* This is to remove the blue highlighting android does when you tap active elements */
/* https://stackoverflow.com/questions/21003535/anyway-to-prevent-the-blue-highlighting-of-elements-in-chrome-when-clicking-quic */
.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.no-select:focus {
  outline: none !important;
}

/* This is to remove light gallery until the css loads */
.desktop-light-gallery {
  display: none;
}

/* Maplibre Styles */
.maplibregl-popup,
.mapboxgl-popup {
  max-width: fit-content !important;
}

.maplibregl-popup-tip,
.mapboxgl-popup-tip {
  visibility: hidden;
}

.maplibregl-popup-content,
.mapboxgl-popup-content {
  border: none;
  border-radius: unset;
  background: none;
  box-shadow: none;
  padding: 0;
  outline: none;
  font-family: var(--font-poppins);
  font-size: 0.875rem;
  line-height: initial;
}

/** PlayCanvas container override*/
#room-builder-embedded-content-container {
  z-index: 10001;
}

/* remove drop shadow on ios slider thumbs */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
}
