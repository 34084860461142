.loader-darkGrey {
  animation: mulShdSpinDarkGrey 1.1s infinite ease;
}
@keyframes mulShdSpinDarkGrey {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #404145, 1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2),
      2.5em 0em 0 0em rgb(64, 65, 69, 0.2), 1.75em 1.75em 0 0em rgb(64, 65, 69, 0.2),
      0em 2.5em 0 0em rgb(64, 65, 69, 0.2), -1.8em 1.8em 0 0em rgb(64, 65, 69, 0.2),
      -2.6em 0em 0 0em rgb(64, 65, 69, 0.5), -1.8em -1.8em 0 0em rgb(64, 65, 69, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(64, 65, 69, 0.7), 1.8em -1.8em 0 0em #404145,
      2.5em 0em 0 0em rgb(64, 65, 69, 0.2), 1.75em 1.75em 0 0em rgb(64, 65, 69, 0.2),
      0em 2.5em 0 0em rgb(64, 65, 69, 0.2), -1.8em 1.8em 0 0em rgb(64, 65, 69, 0.2),
      -2.6em 0em 0 0em rgb(64, 65, 69, 0.2), -1.8em -1.8em 0 0em rgb(64, 65, 69, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgb(64, 65, 69, 0.5), 1.8em -1.8em 0 0em rgb(64, 65, 69, 0.7),
      2.5em 0em 0 0em #404145, 1.75em 1.75em 0 0em rgb(64, 65, 69, 0.2), 0em 2.5em 0 0em rgb(64, 65, 69, 0.2),
      -1.8em 1.8em 0 0em rgb(64, 65, 69, 0.2), -2.6em 0em 0 0em rgb(64, 65, 69, 0.2),
      -1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(64, 65, 69, 0.2), 1.8em -1.8em 0 0em rgb(64, 65, 69, 0.5),
      2.5em 0em 0 0em rgb(64, 65, 69, 0.7), 1.75em 1.75em 0 0em #404145, 0em 2.5em 0 0em rgb(64, 65, 69, 0.2),
      -1.8em 1.8em 0 0em rgb(64, 65, 69, 0.2), -2.6em 0em 0 0em rgb(64, 65, 69, 0.2),
      -1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgb(64, 65, 69, 0.2), 1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2),
      2.5em 0em 0 0em rgb(64, 65, 69, 0.5), 1.75em 1.75em 0 0em rgb(64, 65, 69, 0.7), 0em 2.5em 0 0em #404145,
      -1.8em 1.8em 0 0em rgb(64, 65, 69, 0.2), -2.6em 0em 0 0em rgb(64, 65, 69, 0.2),
      -1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(64, 65, 69, 0.2), 1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2),
      2.5em 0em 0 0em rgb(64, 65, 69, 0.2), 1.75em 1.75em 0 0em rgb(64, 65, 69, 0.5),
      0em 2.5em 0 0em rgb(64, 65, 69, 0.7), -1.8em 1.8em 0 0em #404145, -2.6em 0em 0 0em rgb(64, 65, 69, 0.2),
      -1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgb(64, 65, 69, 0.2), 1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2),
      2.5em 0em 0 0em rgb(64, 65, 69, 0.2), 1.75em 1.75em 0 0em rgb(64, 65, 69, 0.2),
      0em 2.5em 0 0em rgb(64, 65, 69, 0.5), -1.8em 1.8em 0 0em rgb(64, 65, 69, 0.7), -2.6em 0em 0 0em #404145,
      -1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(64, 65, 69, 0.2), 1.8em -1.8em 0 0em rgb(64, 65, 69, 0.2),
      2.5em 0em 0 0em rgb(64, 65, 69, 0.2), 1.75em 1.75em 0 0em rgb(64, 65, 69, 0.2),
      0em 2.5em 0 0em rgb(64, 65, 69, 0.2), -1.8em 1.8em 0 0em rgb(64, 65, 69, 0.5),
      -2.6em 0em 0 0em rgb(64, 65, 69, 0.7), -1.8em -1.8em 0 0em #404145;
  }
}

.loader-white {
  animation: mulShdSpinWhite 1.1s infinite ease;
}
@keyframes mulShdSpinWhite {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgb(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgb(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgb(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgb(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgb(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgb(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgb(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgb(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgb(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgb(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgb(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgb(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgb(255, 255, 255, 0.7),
      2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgb(255, 255, 255, 0.2), 0em 2.5em 0 0em rgb(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgb(255, 255, 255, 0.2), -2.6em 0em 0 0em rgb(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgb(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgb(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgb(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgb(255, 255, 255, 0.2), -2.6em 0em 0 0em rgb(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgb(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgb(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgb(255, 255, 255, 0.2), -2.6em 0em 0 0em rgb(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgb(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgb(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgb(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgb(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgb(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgb(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgb(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgb(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgb(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgb(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgb(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgb(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgb(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgb(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgb(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
