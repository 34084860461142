/** Desktop styles */
.desktop-light-gallery .lg-backdrop {
  z-index: 10002;
}

.desktop-light-gallery .lg-outer {
  z-index: 10002;
  overflow: visible;
}

.desktop-light-gallery .lg-prev::after,
.desktop-light-gallery .lg-next::before {
  content: '';
}

.desktop-light-gallery .lg-prev,
.desktop-light-gallery .lg-next {
  color: #fff;
  border-radius: 50%;
  width: 52px;
  height: 52px;
}

.desktop-light-gallery .lg-close.lg-icon {
  color: white;
  border-radius: 8px;
  border: 1px solid white;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-top: 42px;
  position: absolute;
  right: 0;
  margin-right: -261px;
  pointer-events: all;
}

.desktop-light-gallery .lg-outer {
  width: auto;
  right: 312px;
  left: 40px;
}

.desktop-light-gallery .lg-sub-html {
  text-align: left;
  padding-top: 35px;
  padding-left: 0;
  padding-right: 125px;
  background: #000;
  color: #fff;
  position: fixed;
  right: 0px;
  left: 40px;
}

.desktop-light-gallery .lg-sub-html p,
.mobile-light-gallery .lg-sub-html p {
  font-size: 14px;
}

.desktop-light-gallery .lg-outer .lg-thumb-outer {
  left: auto;
  top: 99px;
  width: 300px !important;
  bottom: 40px;
  position: fixed;
  right: 0;
  max-height: none;
  overflow-y: auto;
}

.desktop-light-gallery .lg-thumb.lg-group {
  width: 300px !important;
  padding-bottom: 30px;
}

.desktop-light-gallery .lg-outer .lg-thumb-item {
  cursor: pointer !important;
  border-color: #000;
}

.desktop-light-gallery .lg-outer .lg-thumb-item.active,
.desktop-light-gallery .lg-outer .lg-thumb-item:hover {
  border-color: #43aa8b;
  border-width: 1px;
}

.desktop-light-gallery .lg-outer .lg-thumb-item img {
  border: 3px solid #000;
  border-radius: 8px;
}

.desktop-light-gallery .lg-outer .lg-toolbar {
  height: 100vh;
  pointer-events: none;
}

.desktop-light-gallery .lg-img-wrap {
  padding-top: 120px;
  padding-bottom: 30px;
}

.desktop-light-gallery .lg-img-wrap img {
  border-radius: 5px;
}

.desktop-light-gallery .lg-media-overlap .lg-toolbar {
  background-image: none;
}

.desktop-light-gallery .lg-icon.lg-zoom-out,
.desktop-light-gallery .lg-icon.lg-zoom-in {
  right: 50%;
  margin-right: -25px;
  bottom: 60px;
  position: absolute;
  pointer-events: all;
  background-color: rgb(0, 0, 0, 0.45);
  border-radius: 50%;
  width: 52px;
  height: 52px;
}

.desktop-light-gallery .lg-toolbar .lg-icon {
  color: #fff;
}

/** Mobile styles */
.mobile-light-gallery .lg-backdrop {
  z-index: 10002;
}

.mobile-light-gallery .lg-outer {
  z-index: 10003;
}

.mobile-light-gallery .lg-prev::after,
.mobile-light-gallery .lg-next::before {
  content: '';
}

.mobile-light-gallery .lg-next,
.mobile-light-gallery .lg-prev {
  bottom: 85px;
  top: auto;
  background: none;
}

.mobile-light-gallery .lg-counter {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 80px;
  z-index: 1060;
}

.mobile-light-gallery .lg-sub-html {
  text-align: left;
  color: white;
  padding-top: 30px;
  padding-left: 24px;
}

.mobile-light-gallery .lg-close.lg-icon {
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-top: 34px;
  margin-right: 24px;
}

.mobile-light-gallery .lg-image {
  padding-bottom: 25px;
}

.mobile-light-gallery .lg-zoomed .lg-image {
  padding-bottom: 0px;
}

.mobile-light-gallery .lg-content {
  top: 0px !important;
}
